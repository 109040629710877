/* ComingSoon.css */
.coming-soon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
    color: #333;
    font-family: Arial, sans-serif;
    text-align: center;
  }
  
  .coming-soon-container h1 {
    font-size: 2.5rem;
  }  